@import "constants.scss";

.form-check-input[type="checkbox"] {
  border-color: $input-border-color;
  border-radius: 0;
}

.form-check-input {
  &:checked,
  &:focus {
    box-shadow: $input-focus-shadow-sizes $input-focus-shadow-color;
  }

  &:checked {
    background-color: $input-checked-color;
    border-color: $input-checked-color;
  }
}
