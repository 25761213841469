.agent-info {
  height: fit-content;
  margin-bottom: 1rem;
  grid-area: agent-info;

  p {
    margin-bottom: 0;
  }

  > :first-child {
    font-weight: bold;
  }
}
