@import "constants.scss";

.uikit-multiSelectReact__wrap {
  max-width: 70%;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .uikit-multiSelectReact__wrap {
    max-width: 100%;
    div {
      font-size: 24px !important;
    }

    div.uikit-multiSelectReact__single-value div div {
      min-height: 24px;
    }

    div#react-select-2-placeholder {
      min-height: 24px;
    }
  }
}
