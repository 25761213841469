.modal-background {
  background-color: rgba(184, 184, 184, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  z-index: 1;
}
