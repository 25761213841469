.request-status-log {
  grid-area: req-status-log;
  margin-bottom: 1rem;
  height: fit-content;

  .log-item {
    margin-bottom: 10px;

    :last-child {
      margin-left: 10px;
    }
  }

  :last-child {
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .request-status-log .log-item p {
    padding-right: 20px;
    width: 100%;
    text-align: end;
  }
}
