@import "constants.scss";

@media (min-width: 320px) and (max-width: 1023px) {
  button.uikit-rectButton__button {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding: 0.375rem 0.75rem;
    font-size: 24px;
    width: 100%;
  }

  @media (orientation: landscape) {
    button.uikit-rectButton__button {
      font-size: 24px;
    }
  }
}
