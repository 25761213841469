@import "buttons.scss";

.buttons-block {
  display: grid;

  > div {
    width: fit-content;
  }

  :last-child {
    justify-self: end;
  }

  :first-child {
    justify-self: start;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .buttons-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 33%));
    justify-items: center;
    width: 30%;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .buttons-block {
    display: grid;
    grid-template-rows: auto;
    justify-items: center;

    > div {
      width: 100%;
    }

    button {
      font-size: 24px;
      height: 100%;
      width: 100%;
      margin-top: 30px;
    }

    > div:first-child button {
      margin-top: 0;
    }
  }
}
