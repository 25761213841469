.client-info-group {
  @import "inputs.scss";
  margin-bottom: 1rem;

  > div {
    margin-bottom: 1rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}
