.my_request {
  padding-top: 50px;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .my_request.container {
    margin: 0;
    padding: 25px 25px 0 25px;
    height: 100%;
    max-width: fit-content;
    width: 100%;

    .main-filter-panel.row {
      width: 95vw;

      span,
      .btn-app {
        font-size: 24px;
      }

      span {
        height: 100%;
        text-align: center;
      }
    }

    .app-table {
      font-size: 15px;
      margin: 0 auto 0 auto;
    }
  }
}
