@import "constants.scss";

@media (min-width: 1024px) and (max-width: 2560px) {
  .main {
    display: grid;
    grid-template-areas:
      "navbar . logout logout"
      "navbar . . ."
      "navbar . component component"
      "navbar . component component";
    grid-template-columns: 10% 20px repeat(2, auto);
    grid-template-rows: 58px 20px auto auto;
    width: 100%;

    margin: 10px 0;

    &_navbar_wrapper {
      display: inline-block;
      grid-area: navbar;
      height: 100%;
      width: 100%;
    }

    &_navbar {
      background-color: white;
      box-shadow: $box-shadow-sizes $box-shadow-color;
      margin-right: 0;
      height: 100%;
      width: 100%;
    }

    &.container-fluid {
      height: 100%;
      margin-top: 0;
      padding-top: 10px;
    }

    .navbar {
      padding-top: 0;
    }

    .navs_container {
      margin: 0 auto auto 0;
      padding: 0;
      width: 100%;

      > .links_wrapper {
        width: 100%;

        .brand_wrapper {
          height: 59px;
          padding: 0px;
          width: 100%;

          span {
            color: $brand-color;
          }
        }
      }

      .navbar-brand {
        margin-left: 5px;
        font-size: 32px;
        font-weight: bold;
        width: 100%;
      }

      a.nav-link.active {
        color: $active-navbar-link-color;
      }
    }

    .navlink-container {
      padding-left: 15px;
    }

    .component {
      background-color: white;
      box-shadow: $box-shadow-sizes $box-shadow-color;
      grid-area: component;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .main {
    display: grid;
    grid-template-areas:
      "navbar navbar logout logout"
      "component component component component"
      "component component component component";
    grid-template-rows: 80px auto;
    height: 100%;
    width: 100%;

    .logout {
      display: flex;
      white-space: nowrap;
      align-items: center;

      p {
        margin: auto 0;
      }
    }

    &_navbar_wrapper {
      background-color: white;
      display: inline-block;
      grid-area: navbar;
      height: 100%;
      width: 100%;

      .links_wrapper {
        display: flex;
      }

      .navlink-container {
        .row {
          display: inline-block;
          padding-left: 25px;
        }
      }

      .brand_wrapper {
        span {
          color: $brand-color;
          font-weight: bold;
          font-size: 48px;
          margin: 0;
          line-height: 55px;
        }
      }

      .navlink-container {
        a.nav-link {
          font-size: 32px;
          line-height: 50px;

          &.active {
            color: $active-navbar-link-color;
          }
        }
      }
    }
  }

  .component {
    background-color: white;
    box-shadow: $box-shadow-sizes $box-shadow-color;
    grid-area: component;

    margin-top: 20px;
    height: 100%;
    width: 100%;
  }
}
