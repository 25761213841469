.new-company-form {
  @import "inputs.scss";
  @import "buttons.scss";

  background-color: white;

  grid-area: form;

  .client-import {
    color: $brand-color;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
  }

  .client-import-input {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1000;
    opacity: 0;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .new-company-form {
    height: 100%;

    .client-import {
      font-size: 12px;
      margin: 0;
    }

    .row {
      padding-bottom: 25px;
      width: 35%;

      > :last-child {
        display: flex;
        justify-content: end;
      }
    }

    div.buttons-block {
      grid-template-columns: repeat(3, fit-content(100px));
      width: 70%;
      margin-top: 25px;

      > :nth-child(n + 2) {
        margin-left: 20px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .new-company-form {
    height: 100%;
    .btn-app {
      font-size: 32px;
    }

    .client-import {
      font-size: 24px;
      padding-bottom: 50px;
    }

    .row {
      padding-bottom: 30px;

      > :last-child {
        display: flex;
        justify-content: end;
      }
    }

    .logout p,
    button {
      font-size: 32px;
    }

    label {
      font-size: 32px;
    }

    div.buttons-block {
      padding-bottom: 10px;
      margin-top: 30px;
    }
  }
}
