@import "bootstrap/scss/bootstrap.scss";
@import "mcb-uikit/main.css";
@import "constants.scss";
@import "icons.scss";

html,
#root,
body {
  height: 100%;
  min-height: 100%;
}

body {
  background-color: $body-background-color;
  margin: 0;
}
