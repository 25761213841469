.sort_container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.select_field,
.select_type {
  max-width: 97%;
}
