.documents-group-form {
  @import "buttons.scss";
  .control-block {
    display: grid;

    > :first-child {
      grid-area: title;
    }

    > :nth-child(3) {
      grid-area: comment;
    }

    > :nth-child(2) {
      grid-area: filename;
    }
  }

  .add-another-doc {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .documents-group-form {
    padding-bottom: 1rem;

    .control-block {
      grid-template-areas: "title filename";
      grid-template-columns: 50% 50%;
      width: 70%;

      > span {
        padding-left: 10px;
        white-space: nowrap;
      }
    }

    &.comment {
      .control-block {
        grid-template-areas: "title filename comment";
        grid-template-columns: 25% 33% 42%;
        width: 70%;
      }
    }

    .add-another-doc > span {
      margin-left: 20px;
      line-height: 32px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .documents-group-form {
    padding-bottom: 1rem;
    width: 100%;

    .control-block {
      grid-template-areas: "title filename" "comment comment";
      grid-template-rows: minmax(0, auto);
      grid-template-columns: 45% 55%;
      width: 100%;
      padding-bottom: 10px;

      > span {
        padding-bottom: 10px;
      }
    }

    .add-another-doc > span {
      margin-left: 20px;
      line-height: 32px;
    }
  }
}
