.client-type {
  @import "checkbox.scss";

  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;

  > div {
    display: flex;
    height: 100%;
    margin-right: 15px;

    > span {
      height: 100%;
      line-height: 100%;
      align-self: center;
    }
  }
}
