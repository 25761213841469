.doc-check-block {
  @import "inputs.scss";

  display: grid;
  align-items: center;

  :nth-child(2) {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .doc-check-block {
    grid-template-columns: 15% 15% 55%;
    padding-bottom: 1rem;

    input.form-control {
      height: 35px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .doc-check-block {
    grid-template-areas: "title filename" "comment comment";
    grid-template-columns: 50% 50%;
    grid-template-rows: auto minmax(0, auto);
    padding-bottom: 2rem;

    :first-child {
      grid-area: title;
    }

    :last-child {
      grid-area: comment;
      padding-bottom: 1rem;
    }

    :nth-child(2) {
      grid-area: filename;
      text-align: end;
    }
  }
}
