@import "constants.scss";

.modal-background.camera {
  position: fixed;
  max-height: 100%;
}

.camera-component {
  video {
    margin: 0 auto;
    object-fit: fill;
  }

  .app-btn-close {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .panel {
    display: grid;
    position: absolute;
    z-index: 1;
    grid-template-rows: repeat(2, auto);
    left: auto;
    right: auto;
    width: 100%;
  }

  .camera-controls-block {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-areas: "images photo confirm";

    button,
    div {
      align-self: center;
      justify-self: center;
    }

    .btn-images {
      border: 1px solid white;
      background-color: white;
      padding: 0;
      grid-area: images;
    }

    .btn-take-photo {
      border: 1px solid white;
      background-color: transparent;
      padding: 0;
      grid-area: photo;

      div {
        background-color: white;
        border: 1px solid $brand-color;
      }

      &:active {
        border-color: $brand-color;

        div {
          background-color: $brand-color;
          border-color: white;
        }
      }
    }

    .btn-confirm-photo {
      border: 1px solid white;
      background-color: white;
      padding: 0;
      grid-area: confirm;
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .camera-component {
    height: 25%;
    width: 25%;
    position: relative;

    .panel {
      bottom: 10px;
    }

    .camera-controls-block {
      display: grid;
      grid-template-columns: repeat(3, 33%);

      .btn-take-photo {
        border-width: 4px;
        border-radius: 16px;
        height: 32px;
        width: 32px;

        div {
          border-width: 2px;
          border-radius: 12px;
          height: 24px;
          width: 24px;
        }
      }

      .btn-images,
      .btn-confirm-photo {
        border-width: 4px;
        border-radius: 16px;
        height: 32px;
        width: 32px;
      }

      .uikit-roundButton__button {
        height: 32px;
        width: 32px;
      }

      .app-icon {
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .modal-background.camera {
    align-items: stretch;
    align-content: stretch;
    justify-content: center;
  }

  .camera-component {
    video {
      height: 100vh;
      width: 100vw;
    }

    .app-btn-close.uikit-roundButton__button {
      height: 96px;
      width: 96px;

      .uikit-roundButton__icon--close24 {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 48px;
        width: 48px;
      }
    }

    .panel {
      bottom: 20px;
    }

    .camera-controls-block {
      .btn-images {
        border-width: 4px;
        border-radius: 48px;
        height: 96px;
        width: 96px;
      }

      .btn-take-photo {
        border-width: 12px;
        border-radius: 48px;
        height: 96px;
        width: 96px;

        div {
          border-width: 6px;
          border-radius: 35px;
          height: 72px;
          width: 72px;
        }
      }

      .btn-confirm-photo {
        border-width: 4px;
        border-radius: 48px;
        height: 96px;
        width: 96px;
      }

      .app-icon {
        svg {
          height: 48px;
          width: 48px;
        }
      }
    }
  }

  @media (orientation: landscape) {
    .camera-component {
      .app-btn-close.uikit-roundButton__button {
        height: 48px;
        width: 48px;

        .uikit-roundButton__icon--arrowDown24 {
          height: 24px;
          width: 24px;
        }

        .uikit-roundButton__icon--close24 {
          height: 24px;
          width: 24px;
        }
      }

      .panel {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: none;
        right: 10px;
        bottom: 0;
        height: 100%;
        width: fit-content;
      }

      .camera-controls-block {
        grid-template-rows: repeat(3, 33%);
        grid-template-columns: 50px;
        grid-template-areas: "images" "photo" "confirm";

        .btn-take-photo {
          border-width: 6px;
          border-radius: 24px;
          height: 48px;
          width: 48px;

          div {
            border-width: 3px;
            border-radius: 18px;
            height: 36px;
            width: 36px;
          }
        }

        .btn-images,
        .btn-confirm-photo {
          border-radius: 24px;
          height: 48px;
          width: 48px;
        }

        .app-icon {
          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}
