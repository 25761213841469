@import "constants.scss";

.photo-controller {
  .app-photo-btn {
    background-color: white;
    border: 1px solid white;
    padding: 0;
    position: absolute;
    z-index: 1;

    &:active {
      border-color: $brand-color;
      background-color: $brand-color;

      div.app-icon {
        color: white;
      }
    }
  }

  .btn-back {
    top: 5px;
    left: 5px;
  }

  .btn-delete {
    top: 5px;
    right: 5px;
  }

  .empty-photo {
    background-color: black;
    color: white;
    height: 100%;
    width: 100%;
  }

  .photo-controller-panel {
    display: grid;
    position: absolute;
    z-index: 1;
    grid-template-areas: "images btn-conf";
    grid-template-columns: 90% 10%;
    width: 100%;

    .images-block {
      overflow: auto;
      grid-area: images;

      .image-wrapper {
        border: 1px solid white;
        margin-left: 20px;
        display: inline-block;

        &.chosen {
          border-color: $brand-color;
        }
      }

      img {
        border-style: solid;
        border-color: white;
        cursor: pointer;
        margin: 0 0 2px 20px;

        &.chosen {
          border-color: $brand-color;
        }
      }
    }

    .btn-confirm {
      align-self: center;
      grid-area: btn-conf;
      justify-self: center;

      &.confirmed {
        border-color: $brand-color;
        background-color: $brand-color;

        div.icon-check {
          color: white;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .photo-controller {
    height: 25%;
    width: 25%;
    position: relative;

    &-panel {
      height: 50px;
      bottom: 5px;
      left: auto;
      right: auto;
    }

    .app-photo-btn {
      border-width: 4px;
      border-radius: 16px;
      height: 32px;
      width: 32px;
    }

    > img {
      height: 100%;
      width: 100%;
    }

    .images-block {
      img {
        border-width: 1px;
        max-height: 50px;
        max-width: 50px;
      }
    }

    .app-icon {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .photo-controller {
    img {
      height: 100vh;
      width: 100vw;
    }

    &-panel {
      height: 150px;
      right: 5px;
      bottom: 10px;
    }

    .app-photo-btn {
      border-width: 4px;
      border-radius: 48px;
      height: 96px;
      width: 96px;
    }

    .images-block img {
      max-height: 150px;
      max-width: 150px;
      border-width: 5px;
    }

    .app-icon {
      svg {
        height: 48px;
        width: 48px;
      }
    }
  }

  @media (orientation: landscape) {
    .photo-controller {
      .app-photo-btn {
        border-width: 4px;
        border-radius: 24px;
        height: 48px;
        width: 48px;
      }

      .app-icon {
        svg {
          height: 24px;
          width: 24px;
        }
      }

      div.photo-controller-panel {
        height: 100px;
        right: 0;
        bottom: 5px;

        grid-template-columns: 95% 4%;
      }

      .images-block img {
        max-height: 100px;
        max-width: 100px;
        border-width: 4px;
      }
    }
  }
}
