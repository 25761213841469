@import "constants.scss";

.app-icon {
  color: $brand-color;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 0.25;
  }
}
