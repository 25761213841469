@import "constants.scss";

.request-status {
  @import "buttons.scss";

  background-color: white;

  display: grid;
  grid-template-areas: $request-template;
  grid-template-columns: $request-columns-sizes;

  .form {
    grid-area: form;
  }

  div.logout {
    box-shadow: 0 0 0 0 transparent;
  }

  &.without-superviser {
    grid-template-areas: $request-template-without-supervizer;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .request-status {
    padding: 30px 30px 0 30px;

    div.buttons-block {
      grid-template-columns: repeat(2, fit-content(100px));
      width: 100%;

      > :first-child {
        margin-right: 20px;
      }
    }
  }

  .form {
    padding-bottom: 1rem;

    .form-title {
      padding: 1.5rem 0;
      font-weight: bold;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .request-status {
    font-size: 24px;
    padding: 50px;
    height: 100%;

    grid-template-rows: repeat(2, 70px) fit-content(70px) 140px 90px auto;
    grid-template-columns: 45% 5% repeat(2, 25%);

    &.without-superviser {
      grid-template-rows: repeat(2, 70px) fit-content(70px) 90px auto;
    }
  }

  div.logout {
    white-space: nowrap;
    .row {
      top: 0;

      div {
        display: flex;
        justify-content: end;
      }
    }
  }

  @media (orientation: landscape) {
    .request-status {
      height: fit-content;
    }
  }
}
