@import "constants.scss";

.new-company {
  background-color: white;
  display: grid;
  grid-template-areas:
    "req-title logout logout logout"
    "form form form form";

  height: 100%;

  .logout {
    box-shadow: 0 0 0 0 transparent;
  }

  div.container-fluid {
    padding: 0;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .new-company {
    grid-template-rows: 60px auto;
    padding: 30px 30px 0 30px;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .new-company {
    grid-template-rows: 80px auto;
    padding: 30px;
  }

  div.logout {
    p,
    button.btn-app {
      font-size: 32px;
    }

    > .row {
      margin: 0;
      :last-child {
        display: flex;
        width: fit-content;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        justify-content: end;
      }
    }
  }

  @media (orientation: landscape) {
    .new-company {
      height: fit-content;
    }
  }
}
