.new-request {
  @import "inputs.scss";
  @import "buttons.scss";
  @import "selector.scss";

  background-color: white;
  display: grid;

  grid-template-areas: $request-template;
  grid-template-columns: $request-columns-sizes;

  &.new {
    grid-template-areas: $request-template-without-supervizer;
  }

  div.logout {
    box-shadow: 0 0 0 0 transparent;
  }

  .req-title {
    grid-area: req-title;
    font-weight: bold;
  }

  .request-status {
    grid-area: req-status;
    font-weight: bold;
  }

  .request-status-log {
    grid-area: req-status-log;
  }

  .agent-info {
    grid-area: agent-info;
  }

  .form {
    grid-area: form;

    .form-title {
      font-weight: bold;
    }

    .form-doc-title {
      font-weight: bold;
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .new-request {
    padding: 30px 30px 0 30px;

    .req-title {
      font-size: 24px;
      line-height: 60px;
    }

    .request-status-log .log-item {
      p {
        padding-right: 20px;
        width: 100%;
        text-align: end;
      }

      :first-child {
        margin-bottom: 0;
      }
    }

    .agent-info :first-child {
      margin-bottom: 0;
    }

    .form {
      padding-bottom: 1rem;

      .form-doc-title {
        padding: 1.5rem 0;
      }

      .row {
        margin-left: 12px;
        width: 70%;

        .col {
          display: flex;
          justify-content: center;
        }
      }
    }

    div.buttons-block {
      grid-template-columns: repeat((3, fit-content(100px)));
      width: 100%;

      button {
        white-space: nowrap;
      }

      :nth-child(2) {
        margin: 0 20px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .new-request {
    padding: 30px;
    font-size: 24px;
    height: 100%;

    grid-template-rows: repeat(2, 60px) fit-content(60px) 140px 90px;
    grid-template-columns: 45% 5% repeat(2, 25%);

    &.new {
      grid-template-rows: repeat(2, 60px) fit-content(60px) 120px;
    }

    button {
      font-size: 24px;
    }
  }

  div.logout .row {
    top: 0px;

    .col {
      display: flex;
      justify-content: end;
    }
  }

  .request-status-log p {
    text-align: end;
  }

  .form {
    max-width: 100%;

    .form-doc-title {
      padding: 1.5rem 0;
    }

    .row {
      margin-left: 0px;
      width: 135%;

      .col {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (orientation: landscape) {
    .new-request {
      height: fit-content;
    }
  }
}
