.confirmation_form {
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0;
}

.auth {
  &_login {
    margin-bottom: 20px;
  }

  &_button {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }

}

@media (min-width: 320px) and (max-width: 1023px) {
  .confirmation {
    &_title {
      font-size: 70px;
    }

    &_form {
      padding: 0px 20px 0px;
    }
  }

  .confirmation_subtitle {
    font-size: 50px;
  }

  .auth_form {
    font-size: 50px;
  }

  input.uikit-defaultInput__input.uikit-defaultInput__h-56 {
    margin-top: 20px;
    font-size: 45px;
    line-height: 20px;
    height: 120px;
    padding: 18px 30px 16px;
  }

  button.uikit-rectButton__button.uikit-rectButton__h-48 {
    height: 120px;
    font-size: 57px;
  }

  p.uikit-defaultInput__error-substring {
    font-size: 40px;
    margin-top: 30px;
  }
}