// colors

$active-navbar-link-color: #dd0a34;

$box-shadow-color: #a6a6a6;

$body-background-color: #d9d9d9;

$brand-color: #dd0a34;

$button-color: #dd0a34;
$button-focus-shadow-color: #f9859c;
$button-hover-color: #f75575;

$input-border-color: #e3e3e8;
$input-checked-color: #dd0a34;
$input-focus-shadow-color: #f9859c;

$placeholder-color: #e3e3e8;
$font-input-client-data-color: rgba(145, 147, 153, 1);

// grid

$request-template: "req-title req-title logout logout"
  "req-status req-status req-status req-status"
  "req-status-log req-status-log req-status-log req-status-log"
  "req-supervizer req-supervizer req-supervizer req-supervizer"
  "agent-info agent-info agent-info agent-info" "form form form form";

$request-template-without-supervizer: "req-title req-title logout logout"
  "req-status req-status req-status req-status"
  "req-status-log req-status-log req-status-log req-status-log"
  "agent-info agent-info agent-info agent-info" "form form form form";

$request-columns-sizes: repeat(4, 25%);

// sizes

$box-shadow-sizes: 0 0 4px 4px;

$button-focus-shadow-sizes: 0 0 0 0.25rem;

$input-focus-shadow-sizes: 0 0 0 0.25rem;
