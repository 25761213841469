.main-filter-panel {
  padding-bottom: 15px;

  @import "selector.scss";
  @import "buttons.scss";

  .form-select {
    width: 300px;
  }

  div.buttons-block {
    grid-template-columns: repeat(2, auto);
    margin: 0 0 0 auto;
    white-space: nowrap;
    width: fit-content;

    > div button {
      margin-top: 0;
    }

    > :last-child {
      padding-left: 20px;
    }
  }

  > .pagination-module {
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
      margin: 0 20px;
      height: fit-content !important;
      align-self: center;
      vertical-align: middle;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  main-filter-panel {
    .btn_wrapper {
      display: flex;
      align-items: center;
    }
  }
}
