@import "constants.scss";

.check-request {
  @import "buttons.scss";

  background-color: white;

  display: grid;
  position: relative;
  grid-template-areas: $request-template;
  grid-template-columns: $request-columns-sizes;

  .form {
    grid-area: form;

    &-title {
      font-weight: bold;
    }
  }

  div.logout {
    box-shadow: 0 0 0 0 transparent;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .check-request {
    padding: 30px 30px 0 30px;
    height: fit-content;
    width: 100%;

    div.buttons-block {
      grid-template-columns: repeat(5, fit-content(100px));
      width: 100%;
      white-space: nowrap;

      > :nth-child(2) {
        margin: 0 20px;
      }
      > :nth-child(4) {
        margin: 0 20px;
      }
    }
  }

  .form {
    padding-bottom: 1rem;

    .form-title {
      padding: 1.5rem 0;
      font-weight: bold;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .check-request {
    height: 100%;
    font-size: 24px;
    padding: 50px;

    grid-template-rows: repeat(2, 59px) fit-content(70px) 160px 90px auto;
    grid-template-columns: 45% 5% repeat(2, 25%);
  }

  div.logout {
    .row {
      top: 0;

      div {
        display: flex;
        justify-content: end;
      }
    }
  }

  @media (orientation: landscape) {
    .check-request {
      height: fit-content;
    }
  }
}
