@import "constants.scss";

.pseudo-button {
  border: 1px solid $button-color;
  border-radius: 0.25rem;

  color: $button-color;

  padding: 0.375rem 0.75rem;

  text-align: center;

  min-width: 100px;

  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .pseudo-button {
    min-width: 130px;
    max-height: 50px;
  }
}
