.client-doc-check {
  .header {
    @import "buttons.scss";

    display: grid;

    span {
      font-weight: bold;
    }

    button {
      width: fit-content;
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .client-doc-check {
    .header {
      padding-bottom: 1rem;

      grid-template-columns: 15% 15%;

      span {
        line-height: 48px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .client-doc-check {
    .header {
      grid-template-columns: 50% 50%;
      padding-bottom: 2rem;

      button {
        font-size: 24px;
      }
    }
  }
}
