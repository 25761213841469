@import "constants.scss";

.uikit-defaultInput__wrap {
  min-width: 70%;
}

input.uikit-defaultInput__input {
  padding: 9px 14px;
}

.uikit-dateInputSingle__wrap {
  min-width: 70%;
}

@media (min-width: 320px) and (max-width: 1023px) {
  input.uikit-defaultInput__input,
  .uikit-dateInputSingle__wrap input {
    font-size: 24px;
  }

  div.uikit-defaultInput__wrap {
    max-width: 100%;
    width: 100%;
  }

  input.uikit-defaultInput__input {
    width: 100%;
  }

  .uikit-dateInputSingle__wrap {
    min-width: 100%;
  }
}
