@import "constants.scss";

.watch-company {
  background-color: white;
  height: 100%;

  display: grid;
  grid-template-areas: "titl title logout logout" "content content content content";

  .logout {
    box-shadow: 0 0 0 0 transparent;
  }

  .company-info {
    display: flex;
    flex-direction: column;
    grid-area: content;

    .doc-item {
      :first-child {
        font-weight: bold;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .watch-company {
    padding: 30px 30px 0 30px;

    grid-template-rows: 100px auto;

    .company-info {
      > :last-child {
        margin-top: 30px;
      }
    }

    .title {
      margin-bottom: 20px;
      line-height: 60px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .watch-company {
    padding: 30px 30px 0 30px;

    grid-template-rows: 100px auto;

    p {
      font-size: 32px;
    }

    .title {
      margin-bottom: 20px;
      line-height: 60px;
    }

    .company-info {
      > :last-child {
        margin-top: 30px;
      }
    }
  }

  @media (orientation: landscape) {
    .watch-company {
      height: fit-content;
      padding-bottom: 30px;
    }
  }
}
