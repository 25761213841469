@import "constants.scss";

.client-info-group {
  .info-block {
    padding-bottom: 1rem;

    p:last-child {
      color: $font-input-client-data-color;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .client-info-group .info-block {
    p {
      display: inline-block;
      width: 50%;
    }

    :last-child {
      text-align: end;
    }
  }
}
