@import "constants.scss";

@media (min-width: 1024px) and (max-width: 2560px) {
  .logout {
    background-color: white;
    box-shadow: $box-shadow-sizes $box-shadow-color;

    display: inline-block;
    grid-area: logout;

    padding: 5px;

    height: 60px;
    width: 100%;

    @import "buttons.scss";

    > .row,
    div {
      height: 100%;
      width: fit-content;
    }

    > div {
      margin: auto 0 auto auto;
    }

    > .row > .col {
      display: flex;
      align-items: center;
    }

    .name_wrapper > p {
      white-space: nowrap;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .logout {
    background-color: white;
    position: relative;
    grid-area: logout;

    @import "buttons.scss";

    .row {
      position: relative;
      top: 15px;
    }

    p {
      font-size: 24px;
      text-align: center;
      margin: 15px 0 0 0;
      line-height: 24px;
    }
  }
}
