.supervizer-form-block {
  grid-area: req-supervizer;

  margin-bottom: 3rem;

  div.uikit-textArea__wrap {
    max-width: 100%;
  }

  p {
    font-weight: bold;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  div.uikit-textArea__wrap {
    height: 4rem;
    width: 400px;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  div.uikit-textArea__wrap {
    height: 6rem;
    width: 600px;
  }
}
