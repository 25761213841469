.req-title {
  grid-area: req-title;
  font-weight: bold;
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .req-title {
    font-size: 24px;
    line-height: 60px;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .req-title {
    font-size: 32px;
    line-height: 60px;
  }
}
