@import "constants.scss";

.input-file {
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  span {
    text-align: end;
    max-width: 100%;
  }

  .placeholder-label {
    color: $placeholder-color;
    line-height: 38px;
  }

  .file-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @import "pseudo-button.scss";
}

@media (min-width: 320px) and (max-width: 1023px) {
  .span {
    max-width: 100%;
  }

  .input-file .placeholder-label {
    text-align: center;
  }
}
