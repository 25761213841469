.popup {
  width: 25%;
  background: white;
  &-header,
  &-body,
  &-footer {
    padding: 16px !important;
    border-bottom: 1px solid #e9ecef !important;
  }
  &-footer {
    border-bottom: none !important;
  }
  &-header > &-title,
  &-body > p {
    margin-bottom: 0;
  }
}
