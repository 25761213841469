.carousel {
  position: absolute;
  opacity: 1;

  img {
    object-fit: cover;
    object-position: 50% 50%;
  }

  &-item {
    div {
      position: relative;

      button {
        position: absolute;

        top: 5px;
        right: 5px;
        z-index: 2;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .carousel {
    height: fit-content;
    min-height: 50%;
    width: 50%;
    min-width: 50%;

    img {
      max-height: 1080px;
      max-width: 1920px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .carousel {
    min-height: 100%;
    min-width: 100%;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }
}
