.client-doc {
  .info-doc {
    display: grid;

    padding-bottom: 1rem;

    :nth-child(2) {
      font-weight: bold;
    }
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .client-doc .info-doc {
    grid-template-columns: 15% 30% 40%;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .client-doc .info-doc {
    grid-template-areas: "title filename" "comment comment";
    grid-template-columns: 50% 30%;
    grid-template-rows: auto minmax(0, auto);

    :first-child {
      grid-area: title;
    }

    :last-child {
      grid-area: comment;
      padding-bottom: 1rem;
    }

    :nth-child(2) {
      grid-area: filename;
    }
  }
}
